import { clsxm } from 'utils/clsxm';

interface SignInDividerProps {
  className?: string;
}

export const SignInDivider = ({ className }: SignInDividerProps) => {
  return (
    <div className={clsxm('flex items-center justify-center w-full py-1', className)}>
      <div className='border border-solid border-t border-gray-200 w-20'></div>
      <div className='mx-2 text-gray-400 uppercase font-semibold text-sm'>or</div>
      <div className='border border-solid border-t border-gray-200 w-20'></div>
    </div>
  );
};
