import { ReactComponent as IconGoogle } from 'assets/svg/icon-google.svg';
import { Loader } from 'components/Loader';
import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRoutes } from 'routes/appRoutes';
import { useRefreshMutation } from 'store/api/auth';

export const GoogleSignin = () => {
  const navigate = useNavigate();
  const [refresh] = useRefreshMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const throwSnackBar = (
      msg = 'You need to register first. Contact administrator, please.',
      options: OptionsObject = { variant: 'info' },
    ) => {
      enqueueSnackbar(msg, options);
      timeout = setTimeout(() => {
        navigate(appRoutes.root());
      }, 3000);
    };
    const oauth = async () => {
      const code = searchParams.get('code');
      const msg = searchParams.get('msg');
      if (!code) {
        throwSnackBar();
        return;
      } else if (msg && msg === 'error') {
        throwSnackBar();
      } else {
        try {
          await refresh().unwrap();
          navigate(appRoutes.root());
        } catch (error) {
          throwSnackBar('Something went wrong. Please, try again later.', { variant: 'error' });
        }
      }
    };

    oauth();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <IconGoogle width={40} height={40} className='mt-5 mx-auto' />
      <div className='h-screen'>
        <Loader size={40} />
      </div>
    </div>
  );
};
