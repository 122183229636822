import { HomePageAvatar } from 'components/HomePageAvatar';
import { Iconify } from 'components/Iconify';
import { config } from 'shared/config';

export const Home = () => {
  return (
    <div
      className='
      w-full
      min-h-screen
    '
    >
      <div className='min-h-screen bg-[url("assets/images/bg-main.jpg")] bg-cover text-white'>
        <div className='max-w-7xl mx-auto p-4 sm:px-5 flex'>
          <div className='ml-auto'>
            <HomePageAvatar />
          </div>
        </div>
        <div className='mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left'>
          <div className='px-4 lg:w-1/2 sm:px-8 xl:pr-16 mt-12'>
            <h2
              className='
                font-DMSans
                text-sm uppercase
              '
            >
              Hanna in English
            </h2>
            <p
              className='
                mt-3
                max-w-md
                mx-auto
                text-4xl
                sm:text-4xl
                md:text-5xl
                md:mt-5
                md:max-w-3xl
                uppercase
                '
            >
              Tailor-made english lessons to help you reach your goals
            </p>
            <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
              <div className='rounded-md shadow'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={config.TELEGRAM_URL}
                  className='
                  w-full
                  flex
                  items-center
                  justify-center
                  gap-3
                  px-8
                  py-3
                  border
                  border-transparent
                  text-base font-medium
                  rounded-full
                  text-black
                  hover:bg-yellow-100
                  bg-yellow-50
                  md:py-4
                  md:text-lg
                  md:px-10'
                >
                  Get Started
                  <Iconify icon='ic:baseline-telegram' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
