import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Iconify } from 'components/Iconify';
import { useLogout } from 'hooks/useLogout';
import type { MouseEventHandler } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { navConfig } from 'shared/navConfig';
import { type Nullable } from 'shared/types';
import { useWhoamiQuery } from 'store/api/auth';

export const HomePageAvatar = () => {
  const [open, setOpen] = useState<Nullable<Element>>(null);
  const { data: user } = useWhoamiQuery();
  const navigate = useNavigate();
  const { onLogout } = useLogout();

  const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onNavigate = (path: string) => () => {
    handleClose();
    navigate(path);
  };

  const logout = () => {
    handleClose();
    onLogout();
  };

  if (!user) return <Link to='/login'>Login</Link>;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src='/images/avatar_25.jpeg'
          alt='avatar'
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.firstName?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1,
              ml: 0.75,
              width: 200,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          {user?.firstName && user?.lastName && (
            <Typography variant='subtitle2' noWrap>
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
          )}
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {user &&
          navConfig[user?.role].homeAvatarNav.map((option) => (
            <MenuItem key={option.title} onClick={onNavigate(option.path)}>
              <Box sx={{ display: 'flex', gap: '15px' }}>
                <Iconify icon={option.icon} sx={{ color: '#637381' }} />
                {option.title}
              </Box>
            </MenuItem>
          ))}

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={logout}
          sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
        >
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <Iconify icon='ic:baseline-logout' />
            Logout
          </Box>
        </MenuItem>
      </Popover>
    </>
  );
};
