import { Avatar, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { NavLink } from 'react-router-dom';
import type { Student } from 'shared/types';

interface StudentCardProps {
  student: Student;
}

export const StudentCard = ({ student: { id, firstName, lastName } }: StudentCardProps) => {
  return (
    <Card>
      <CardActionArea>
        <NavLink to={`${id}`}>
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: red[500] }}>{firstName?.charAt(0).toUpperCase()}</Avatar>
            <Typography variant='h6' sx={{ ml: 2 }}>
              {`${firstName} ${lastName}`}
            </Typography>
          </CardContent>
        </NavLink>
      </CardActionArea>
    </Card>
  );
};
