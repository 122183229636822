import { config } from 'shared/config';

export const preloadState = () => {
  try {
    let preloadedState = {};

    const serializedAuth = localStorage.getItem(config.AUTH_LOCAL_STORAGE_KEY);

    if (serializedAuth) {
      const auth = JSON.parse(serializedAuth);

      preloadedState = { auth };

      return preloadedState;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
