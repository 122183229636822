import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues } from 'modules/Login/constants/defaultValues';
import type { MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { navConfig } from 'shared/navConfig';
import type { AuthBodyPayload, Nullable } from 'shared/types';
import { loginValidationSchema } from 'shared/validationSchemas';
import { useLazyWhoamiQuery, useLoginMutation } from 'store/api/auth';
import { setAuth } from 'store/slices/auth';
import { getErrorMessage } from 'utils/getErrorMessage';

import { useAppDispatch } from './useAppDispatch';

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const emailInput = useRef<Nullable<HTMLInputElement>>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [login, { isLoading }] = useLoginMutation();
  const [trigger] = useLazyWhoamiQuery();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AuthBodyPayload>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(loginValidationSchema),
  });

  const { ref, ...restInputRegister } = register('email');

  const emailInputRefCallback = (element: Nullable<HTMLInputElement>) => {
    ref(element);
    emailInput.current = element;
  };

  const onSubmit: SubmitHandler<AuthBodyPayload> = async (data, event) => {
    try {
      const res = await login(data).unwrap();
      if (res) {
        dispatch(setAuth(res));
        const user = await trigger().unwrap();
        navigate(navConfig[user.role].redirectPath);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      if (message) {
        setErrorMessage(message);
      }
    }
    event?.preventDefault();
    reset();
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const onShowPassword: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  return {
    showPassword,
    errorMessage,
    handleSubmit,
    errors,
    restInputRegister,
    emailInputRefCallback,
    onSubmit,
    onShowPassword,
    register,
    isLoading,
  };
};
