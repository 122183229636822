import { ENDPOINTS } from 'shared/endpoints';
import { mapTeachers } from 'shared/mappers';
import type { Teachers } from 'shared/types';
import type { CreateTeacherBody, UpdateTeacherBody } from 'store/types';

import { baseApi } from '../base';

export const teachersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTeachers: build.query<Teachers, void>({
      query: () => ({
        url: ENDPOINTS.TEACHERS,
      }),
      providesTags: ['Teachers'],
      transformResponse: mapTeachers,
    }),
    createTeacher: build.mutation<unknown, CreateTeacherBody>({
      query: (body) => ({
        url: ENDPOINTS.TEACHERS,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Teachers', 'Students', 'Users'],
    }),
    updateTeacher: build.mutation<unknown, { id: number; body: UpdateTeacherBody }>({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS.TEACHERS}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Teachers', 'Students'],
    }),
    deleteTeacher: build.mutation<unknown, number>({
      query: (id) => ({
        url: `${ENDPOINTS.TEACHERS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Teachers', 'Students', 'Users'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetTeachersQuery, useCreateTeacherMutation, useDeleteTeacherMutation, useUpdateTeacherMutation } =
  teachersApi;
