import * as Yup from 'yup';

const firstName = Yup.string().label('First name').min(2).max(20);

const lastName = Yup.string().label('Last name').min(2).max(20);

const email = Yup.string().label('Email address').trim().email().required();

const password = Yup.string().label('Password').trim().required().min(10).max(20);

const role = Yup.string().required();

const balance = Yup.number().max(10000);

export const loginValidationSchema = Yup.object().shape({
  email,
  password,
});

export const createUserValidationSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password,
  role,
});

export const createTeacherValidationSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password,
});

export const createStudentValidationSchema = Yup.object().shape({
  email,
  password,
  balance,
});

export const updateTeacherValidationSchema = Yup.object().shape({
  firstName,
  lastName,
});

export const updateStudentValidationSchema = Yup.object().shape({
  firstName,
  lastName,
  balance,
});
