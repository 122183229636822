import { DashboardLayout } from 'components/DashboardLayout';
import { Loader } from 'components/Loader';
import { useAppSelector } from 'hooks/useAppSelector';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import type { UserRole } from 'shared/types';
import { useWhoamiQuery } from 'store/api/auth';
import { selectIsAuthed } from 'store/slices/auth';

import { appRoutes, privatePaths } from './appRoutes';

export const PrivateRoute = ({ role }: { role: UserRole }) => {
  const isAuthed = useAppSelector(selectIsAuthed);
  const { data, isLoading } = useWhoamiQuery();
  const { pathname } = useLocation();

  if (isLoading) return <Loader />;

  if (!isAuthed && privatePaths.includes(pathname)) {
    return <Navigate to={appRoutes.login()} replace />;
  } else if (data?.role !== role) {
    return <div>UNAUTHORIZED</div>;
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};
