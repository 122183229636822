import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { Iconify } from 'components/Iconify';
import { Loader } from 'components/Loader';
import type { RowProps } from 'modules/GeneralTable';
import { useState } from 'react';
import type { User } from 'shared/types';
import { getDeleteUrlByRole, getRestoreUrlByRole } from 'shared/utils';
import { useDeleteUserMutation, useRestoreUserMutation } from 'store/api/users';

export const UserTableRow = ({ data, selected, handleClick }: RowProps<User>) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const [deleteUser, { isLoading }] = useDeleteUserMutation();
  const [restoreUser, { isLoading: restoreIsLoading }] = useRestoreUserMutation();

  const { id, uuid, firstName, lastName, email, role, updatedAt, isActive } = data;

  const getname = () => {
    if (firstName === 'n/a' && lastName === 'n/a') {
      return 'n/a';
    }
    if (firstName === 'n/a' && lastName !== 'n/a') {
      return lastName;
    }
    if (lastName === 'n/a' && firstName !== 'n/a') {
      return firstName;
    }
    return `${firstName} ${lastName}`;
  };

  const name = getname();

  const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const onDeleteUser = () => {
    setOpenConfirmation(true);
    handleCloseMenu();
  };

  const onConfirm = () => {
    const url = getDeleteUrlByRole(role, id, uuid);
    deleteUser({ url });
    setOpenConfirmation(false);
  };

  const onCancel = () => {
    setOpenConfirmation(false);
  };

  const onRestoreUser = () => {
    const url = getRestoreUrlByRole(role, id, uuid);
    restoreUser({ url });
    handleCloseMenu();
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role='checkbox' selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell component='th' scope='row' padding='none'>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar alt={name} />
            <Typography variant='subtitle2' noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{email}</TableCell>

        <TableCell>{role}</TableCell>

        <TableCell>{updatedAt}</TableCell>

        <TableCell align='center'>
          <Chip
            color={isActive ? 'success' : 'error'}
            label={isActive ? 'Active' : 'Removed'}
            variant='outlined'
            size='small'
          />
        </TableCell>

        <TableCell align='right'>
          {isLoading || restoreIsLoading ? (
            <Box sx={{ width: 36, height: 36 }}>
              <Loader size={20} />
            </Box>
          ) : (
            <IconButton onClick={handleOpenMenu}>
              <Iconify icon='eva:more-vertical-fill' />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Iconify icon='eva:edit-fill' sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {!isActive && (
          <MenuItem onClick={onRestoreUser}>
            <Iconify icon='mdi:restore' sx={{ mr: 2 }} />
            Restore
          </MenuItem>
        )}

        <MenuItem onClick={onDeleteUser} sx={{ color: 'error.main' }}>
          <Iconify icon='eva:trash-2-outline' sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <ConfirmationDialog
        title='Delete User'
        content='This action cannot be undone!'
        subContent={`Are you sure you want to delete ${name}?`}
        open={openConfirmation}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};
