import { Container, Stack } from '@mui/material';
import { StudentCard } from 'components/StudentCard';
import { useGetStudentsQuery } from 'store/api/students';

export const TeacherStudents = () => {
  const { data } = useGetStudentsQuery();

  if (data && data.length === 0) {
    return <Container>No students found</Container>;
  }

  return (
    <Container>
      <Stack direction='column' gap={2}>
        {data?.map((student) => {
          return <StudentCard key={student.id} student={student} />;
        })}
      </Stack>
    </Container>
  );
};
