import { ENDPOINTS } from 'shared/endpoints';
import type { AuthBodyPayload, User } from 'shared/types';
import type { AuthRoot } from 'store/types';

import { baseApi } from '../base';

const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    whoami: build.query<User, void>({
      query: () => ({
        url: ENDPOINTS.WHOAMI,
        method: 'GET',
      }),
    }),
    login: build.mutation<AuthRoot, AuthBodyPayload>({
      query: (body) => ({
        url: ENDPOINTS.LOGIN,
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<unknown, void>({
      query: () => ({
        url: ENDPOINTS.LOGOUT,
        method: 'POST',
      }),
    }),
    refresh: build.mutation<unknown, void>({
      query: () => ({
        url: ENDPOINTS.REFRESH,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useLoginMutation, useLogoutMutation, useWhoamiQuery, useLazyWhoamiQuery, useRefreshMutation } = authApi;
