import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { Nullable, Teacher } from 'shared/types';
import { compareTwoArrays, getEditTecherValues, getUpdateTeacherBody } from 'shared/utils';
import { updateTeacherValidationSchema } from 'shared/validationSchemas';
import { useUpdateTeacherMutation } from 'store/api/teachers';
import type { UpdateTeacherBody } from 'store/types';
import { getErrorMessage } from 'utils/getErrorMessage';

export const useEditTeacher = (teacher: Teacher, cb: () => void) => {
  const firstNameInput = useRef<Nullable<HTMLInputElement>>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [updateTeacher, { isLoading }] = useUpdateTeacherMutation();

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    getValues,
    getFieldState,
  } = useForm<UpdateTeacherBody>({
    defaultValues: getEditTecherValues(teacher),
    values: getEditTecherValues(teacher),
    mode: 'onSubmit',
    resolver: yupResolver(updateTeacherValidationSchema),
  });

  const { ref, ...restInputRegister } = register('firstName');

  const firstNameRefCallback = (element: Nullable<HTMLInputElement>) => {
    ref(element);
    firstNameInput.current = element;
  };

  const areNotEqual = !compareTwoArrays(
    getValues('students'),
    teacher.students.map(({ id }) => id),
  );

  const areDirty = {
    ...(dirtyFields.firstName && { firstName: dirtyFields.firstName }),
    ...(dirtyFields.lastName && { lastName: dirtyFields.lastName }),
    ...(areNotEqual && { students: areNotEqual }),
  };

  const isDirty = Object.values(areDirty).some((value) => value);

  const onSubmit: SubmitHandler<UpdateTeacherBody> = async (data, event) => {
    try {
      const body = getUpdateTeacherBody(data, areDirty);
      await updateTeacher({ id: teacher.id, body }).unwrap();
      cb();
    } catch (err) {
      const message = getErrorMessage(err);
      if (message) {
        setErrorMessage(message);
      }
    }
    event?.preventDefault();
    reset();
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  return {
    restInputRegister,
    firstNameRefCallback,
    watch,
    control,
    register,
    handleSubmit,
    onSubmit,
    reset,
    errors,
    isLoading,
    getValues,
    errorMessage,
    areDirty,
    isDirty,
    dirtyFields,
    getFieldState,
  };
};
