import { TableCell, TableRow } from '@mui/material/';

interface GeneralTableEmptyRowsProps {
  emptyRows: number;
  height?: number;
}

export const GeneralTableEmptyRows = ({ emptyRows, height }: GeneralTableEmptyRowsProps) => {
  if (!emptyRows) {
    return null;
  }

  return (
    <TableRow
      sx={{
        ...(height && {
          height: height * emptyRows,
        }),
      }}
    >
      <TableCell colSpan={9} />
    </TableRow>
  );
};
