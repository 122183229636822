import { ENDPOINTS } from 'shared/endpoints';
import { mapStudents } from 'shared/mappers';
import type { Students } from 'shared/types';
import type { CreateStudentBody, UpdateStudentBody } from 'store/types';

import { baseApi } from '../base';

export const studentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStudents: build.query<Students, void>({
      query: () => ({
        url: ENDPOINTS.STUDENTS,
      }),
      providesTags: ['Students'],
      transformResponse: mapStudents,
    }),
    createStudent: build.mutation<unknown, CreateStudentBody>({
      query: (body) => ({
        url: ENDPOINTS.STUDENTS,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Students', 'Teachers'],
    }),
    updateStudent: build.mutation<unknown, { id: number; body: UpdateStudentBody }>({
      query: ({ id, body }) => ({
        url: `${ENDPOINTS.STUDENTS}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Teachers', 'Students'],
    }),
    deleteStudent: build.mutation<unknown, number>({
      query: (id) => ({
        url: `${ENDPOINTS.STUDENTS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Students', 'Teachers'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetStudentsQuery, useCreateStudentMutation, useDeleteStudentMutation, useUpdateStudentMutation } =
  studentsApi;
