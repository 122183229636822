import type { MapProperty, Order } from 'shared/types';

export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

export function applyFilter<T>({
  data,
  comparator,
  filterName,
  filterProp,
}: {
  data: T[];
  comparator: (a: T, b: T) => number;
  filterName: string;
  filterProp: MapProperty<T>;
}) {
  const stabilizedThis: [T, number][] = data.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  data = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    data = data.filter((data) => filterProp(data).toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  return data;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}
