import { ENDPOINTS } from 'shared/endpoints';
import { mapUsers } from 'shared/mappers';
import type { Users } from 'shared/types';

import { baseApi } from '../base';

interface CreateUserArgs {
  url: string;
  body: {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    balance?: number;
  };
}

const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<Users, void>({
      query: () => ({
        url: ENDPOINTS.USERS,
      }),
      transformResponse: mapUsers,
      providesTags: ['Users'],
    }),
    createUser: build.mutation<unknown, CreateUserArgs>({
      query: ({ url, body }) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: build.mutation<unknown, { url: string }>({
      query: ({ url }) => ({
        url,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    restoreUser: build.mutation<unknown, { url: string }>({
      query: ({ url }) => ({
        url,
        method: 'PATCH',
      }),
      invalidatesTags: ['Users', 'Teachers', 'Students'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetUsersQuery, useCreateUserMutation, useDeleteUserMutation, useRestoreUserMutation } = usersApi;
