import { alpha, Avatar, Box, Typography } from '@mui/material';
import type { Nullable, User } from 'shared/types';

export const Account = ({ whoami }: { whoami?: Nullable<User> }) => {
  return (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar />

      <Box sx={{ ml: 2 }}>
        <Typography variant='subtitle2'>{whoami?.email}</Typography>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {whoami?.role}
        </Typography>
      </Box>
    </Box>
  );
};
