import type {
  FlatUser,
  RootStudent,
  RootStudentInTeacher,
  RootTeacher,
  RootTeacherInStudent,
  Students,
  Teachers,
  Users,
  WithUser,
} from 'shared/types';
import { formatDate, notApplicable } from 'shared/utils';

export const mapUsers = (users: Users): Users =>
  users.map((users) => {
    const { createdAt: created, updatedAt: updated, firstName: first, lastName: last, ...rest } = users;
    const [createdAt, updatedAt] = [created, updated].map(formatDate);
    const [firstName, lastName] = [first, last].map(notApplicable);
    return {
      createdAt,
      updatedAt,
      firstName,
      lastName,
      ...rest,
    };
  });

export function mapFlatUser<T extends { createdAt: string; updatedAt: string }>(data: WithUser<T>[]): FlatUser<T>[] {
  return data.map(({ user, createdAt: created, updatedAt: updated, ...rest }) => {
    const { firstName, lastName, email, role, isActive } = user;
    const [createdAt, updatedAt] = [created, updated].map(formatDate);
    return {
      firstName,
      lastName,
      email,
      role,
      createdAt,
      updatedAt,
      isActive,
      ...rest,
    };
  });
}

export const mapFlatTeachers = mapFlatUser<RootTeacher>;
export const mapFlatTeachersInStudent = mapFlatUser<RootTeacherInStudent>;

export const mapFlatStudents = mapFlatUser<RootStudent>;
export const mapFlatStudentsInTeacher = mapFlatUser<RootStudentInTeacher>;

export const transformRootTeachers = (data: WithUser<RootTeacher>[]): Teachers => {
  return mapFlatTeachers(data).map(({ students, ...rest }) => ({
    ...rest,
    students: mapFlatStudentsInTeacher(students),
  }));
};

export const transformRootStudents = (data: WithUser<RootStudent>[]): Students => {
  return mapFlatStudents(data).map(({ teachers, ...rest }) => ({
    ...rest,
    teachers: mapFlatTeachersInStudent(teachers),
  }));
};

export const mapTeachers = (data: WithUser<RootTeacher>[]): Teachers =>
  transformRootTeachers(data).filter(({ isActive }) => isActive);

export const mapStudents = (data: WithUser<RootStudent>[]): Students =>
  transformRootStudents(data).filter(({ isActive }) => isActive);
