import type { HeadLabel } from 'shared/types';

export const usersTableHead: HeadLabel[] = [
  { id: 'lastName', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role' },
  { id: 'updatedAt', label: 'Updated At' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'editCol', label: '' },
];

export const teachersTableHead: HeadLabel[] = [
  { id: 'lastName', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'updatedAt', label: 'Updated At' },
  { id: 'expandCol', label: 'Students', align: 'center' },
  { id: 'editCol', label: '' },
];

export const studentsTableHead: HeadLabel[] = [
  { id: 'lastName', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'teachers', label: 'Teachers' },
  { id: 'balance', label: 'Balance', align: 'center' },
  { id: 'editCol', label: '' },
];
