import { yupResolver } from '@hookform/resolvers/yup';
import type { MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { type Nullable, Role } from 'shared/types';
import { createUserValidationSchema } from 'shared/validationSchemas';
import { useCreateUserMutation } from 'store/api/users';
import type { CreateUserBody } from 'store/types';
import { getErrorMessage } from 'utils/getErrorMessage';

const defaultValues: CreateUserBody = {
  email: '',
  password: '',
  role: '',
  firstName: '',
  lastName: '',
  balance: 0,
};

const getCreateUserUrlAndBody = (
  data: CreateUserBody,
): {
  url: string;
  body: CreateUserBody;
} => {
  const { email, password, role } = data;

  const body = {
    email,
    password,
    role,
    ...(data.firstName && { firstName: data.firstName }),
    ...(data.lastName && { lastName: data.lastName }),
    ...(data.balance && data.balance > 0 && { balance: data.balance }),
  };

  const map: { [key: string]: { url: string } } = {
    [Role.Admin]: { url: '/users' },
    [Role.Teacher]: { url: '/teachers' },
    [Role.Student]: { url: '/students' },
  };

  return { ...map[role], body };
};

export const useCreateUser = (cb: () => void) => {
  const firstNameInput = useRef<Nullable<HTMLInputElement>>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [role, setRole] = useState('');
  const [createUser, { isLoading, isSuccess }] = useCreateUserMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm<CreateUserBody>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(createUserValidationSchema),
  });

  const { ref, ...restInputRegister } = register('firstName');

  const firstNameRefCallback = (element: Nullable<HTMLInputElement>) => {
    ref(element);
    firstNameInput.current = element;
  };

  const onShowPassword: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const onSubmit: SubmitHandler<CreateUserBody> = async (data, event) => {
    const payload = getCreateUserUrlAndBody(data);
    try {
      await createUser(payload).unwrap();
      cb();
    } catch (err) {
      const message = getErrorMessage(err);
      if (message) {
        setErrorMessage(message);
      }
    }
    event?.preventDefault();
    reset();
    setRole('');
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  return {
    showPassword,
    errorMessage,
    firstNameInput,
    firstNameRefCallback,
    handleSubmit,
    errors,
    isLoading,
    restInputRegister,
    onShowPassword,
    onSubmit,
    getValues,
    register,
    reset,
    isSuccess,
    role,
    setRole,
  };
};
