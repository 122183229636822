import { Container, Stack, Typography } from '@mui/material';
import { GeneralTable } from 'modules/GeneralTable';
import { teachersTableHead } from 'shared/tableConfig';
import type { Teacher } from 'shared/types';
import { useGetTeachersQuery } from 'store/api/teachers';

import { NewTeacher } from './components/NewTeacher';
import { TeacherTableRow } from './components/TeacherTableRow';

export const Teachers = () => {
  const { data } = useGetTeachersQuery();

  return (
    <Container>
      <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
        <Typography variant='h4'>Teachers</Typography>
        <NewTeacher />
      </Stack>

      <GeneralTable<Teacher>
        data={data || []}
        tableHead={teachersTableHead}
        selectedProp={(data) => data.email}
        filterProp={(data) => data.lastName}
        keyProp={(data) => `${data.id}`}
        Row={TeacherTableRow}
      />
    </Container>
  );
};
