import { LoadingButton } from '@mui/lab';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Iconify } from 'components/Iconify';
import { useCreateUser } from 'hooks/useCreateUser';
import { useState } from 'react';
import { Role } from 'shared/types';

export const NewUser = () => {
  const [open, setOpen] = useState(false);
  const {
    showPassword,
    onShowPassword,
    onSubmit,
    handleSubmit,
    firstNameRefCallback,
    restInputRegister,
    errors,
    register,
    isLoading,
    errorMessage,
    reset,
    role,
    setRole,
  } = useCreateUser(() => setOpen(false));

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    reset();
    setRole('');
  };

  const roleOnChange = (e: SelectChangeEvent<string>) => {
    setRole(e.target.value);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant='contained' color='inherit' startIcon={<Iconify icon='eva:plus-fill' />}>
        New User
      </Button>
      <Modal
        disableRestoreFocus
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-new-user'
        aria-describedby='modal-new-user-form'
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 5,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 15, right: 15 }}>
                <Iconify icon='eva:close-fill' />
              </IconButton>

              <Typography variant='h4'>New User</Typography>

              <TextField
                {...restInputRegister}
                autoFocus
                ref={firstNameRefCallback}
                name='firstName'
                label='First Name'
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                required
              />
              <TextField
                {...register('lastName')}
                name='lastName'
                label='Last Name'
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                required
              />
              <TextField
                {...register('email')}
                name='email'
                label='Email address'
                error={!!errors.email}
                helperText={errors.email?.message}
                required
              />
              <TextField
                {...register('password')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={onShowPassword} edge='end'>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
                name='password'
                label='Password'
                required
              />

              <FormControl>
                <InputLabel required id='role'>
                  Role
                </InputLabel>
                <Select
                  {...register('role')}
                  value={role}
                  name='role'
                  label='Role'
                  labelId='role'
                  error={!!errors.role}
                  onChange={roleOnChange}
                >
                  <MenuItem value={Role.Admin}>Admin</MenuItem>
                  <MenuItem value={Role.Teacher}>Teacher</MenuItem>
                  <MenuItem value={Role.Student}>Student</MenuItem>
                </Select>
              </FormControl>

              {role === Role.Student && (
                <TextField
                  {...register('balance')}
                  error={!!errors.balance}
                  name='balance'
                  label='Balance'
                  type='number'
                />
              )}

              {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}

              <LoadingButton type='submit' variant='contained' color='inherit' size='large' loading={isLoading}>
                Add
              </LoadingButton>
            </Stack>
          </form>
        </Card>
      </Modal>
    </div>
  );
};
