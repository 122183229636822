import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AuthRoot } from 'store/types';
import type { AuthState } from 'store/types';
import type { RootState } from 'store/types';

export const initialState: AuthState = {
  isAuthed: false,
  accessToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, { payload: { accessToken } }: PayloadAction<AuthRoot>) => {
      state.accessToken = accessToken;
      state.isAuthed = true;
    },
    resetAuth: () => initialState,
  },
});

export const { setAuth, resetAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
export const selectIsAuthed = (state: RootState) => state.auth.isAuthed;

export default authSlice.reducer;
