import 'react-toastify/dist/ReactToastify.css';

import { createTheme, CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import type { PropsWithChildren } from 'react';
import { createContext, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { customShadows } from './customShadows';
import { overrides } from './overrides';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const memoizedTheme = useMemo(
    () => ({
      palette: {
        ...palette(),
        mode,
      },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    [mode],
  );

  const theme = createTheme(memoizedTheme);
  theme.components = overrides(theme);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
        <ToastContainer position='bottom-left' />
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  );
};
