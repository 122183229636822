import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Iconify } from 'components/Iconify';
import { useCreateStudent } from 'hooks/useCreateStudent';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useGetTeachersQuery } from 'store/api/teachers';

export const NewStudent = () => {
  const theme = useTheme();
  const { data: teachers, isLoading: teachersLoading } = useGetTeachersQuery();
  const [open, setOpen] = useState(false);
  const {
    showPassword,
    errorMessage,
    register,
    handleSubmit,
    reset,
    errors,
    firstNameRefCallback,
    onShowPassword,
    restInputRegister,
    onSubmit,
    isLoading,
    control,
    watch,
  } = useCreateStudent(() => setOpen(false));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const teachersList = teachers?.map(({ id, firstName, lastName }) => ({
    id,
    name: `${firstName} ${lastName}`,
  }));

  const getTeacherName = (id: number) => {
    const teacher = teachersList?.find(({ id: teacherId }) => teacherId === id);
    return teacher?.name;
  };

  const getSelectedItemStyles = (id: number) => {
    const selectedTeachers = watch('teachers');

    if (selectedTeachers && selectedTeachers.includes(id)) {
      return {
        fontWeight: theme.typography.fontWeightMedium,
      };
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant='contained' color='inherit' startIcon={<Iconify icon='eva:plus-fill' />}>
        New Student
      </Button>
      <Modal
        disableRestoreFocus
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-new-Student'
        aria-describedby='modal-new-Student-form'
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 5,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 15, right: 15 }}>
                <Iconify icon='eva:close-fill' />
              </IconButton>

              <Typography variant='h4'>New Student</Typography>

              <TextField
                {...restInputRegister}
                autoFocus
                ref={firstNameRefCallback}
                name='firstName'
                label='First Name'
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <TextField
                {...register('lastName')}
                name='lastName'
                label='Last Name'
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
              <TextField
                {...register('email')}
                name='email'
                label='Email address'
                error={!!errors.email}
                helperText={errors.email?.message}
                required
              />
              <TextField
                {...register('password')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={onShowPassword} edge='end'>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
                name='password'
                label='Password'
                required
              />

              <TextField
                {...register('balance')}
                type='number'
                error={!!errors.balance}
                helperText={errors.balance?.message}
                name='balance'
                label='Balance'
              />

              <Controller
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id='teachers'>Teachers</InputLabel>
                    <Select
                      {...field}
                      multiple
                      name='teachers'
                      label='teachers'
                      labelId='teachers'
                      error={!!errors.teachers}
                      input={<OutlinedInput label='Teachers' />}
                      renderValue={(selected) => {
                        const Teachers = selected.map(getTeacherName);

                        if (selected.length === 0) {
                          return <em>Select Teachers</em>;
                        }
                        return Teachers.join(', ');
                      }}
                    >
                      <MenuItem disabled value=''>
                        <em>Select Teachers</em>
                      </MenuItem>
                      {teachersList?.map(({ id, name }) => (
                        <MenuItem key={`${id}-${name}`} value={id} style={getSelectedItemStyles(id)}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name='teachers'
                control={control}
              />

              {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}

              <LoadingButton
                type='submit'
                variant='contained'
                color='inherit'
                size='large'
                loading={isLoading || teachersLoading}
              >
                Add
              </LoadingButton>
            </Stack>
          </form>
        </Card>
      </Modal>
    </div>
  );
};
