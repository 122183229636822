import { styled } from '@mui/system';
import { useTime } from 'hooks/useTime';
import { Fragment } from 'react';

export const Clock = () => {
  const { hours, minutes, seconds } = useTime();

  return (
    <Svg viewBox='0 0 300 300'>
      <g transform='translate(150,150)'>
        <Frame r='110' />

        {minuteMarkers.map((m, i) => (
          <Fragment key={i}>{drawMinuteMarker(m)}</Fragment>
        ))}
        {fiveMinuteMarkers.map((m, i) => (
          <Fragment key={i}>{draw5MinMarker(m)}</Fragment>
        ))}

        {hourLabels.map((l, i) => (
          <Fragment key={i}>{drawHourLabel(l, i)}</Fragment>
        ))}

        <HoursHand transform={`rotate(${hours * 30 + (minutes / 60) * 30},0,0)`}>
          <line strokeWidth='4' x1='0' y1='15' x2='0' y2='-60' />
        </HoursHand>

        <MinutesHand transform={`rotate(${minutes * 6},0,0)`}>
          <line strokeWidth='2' x1='0' y1='20' x2='0' y2='-80' />
        </MinutesHand>

        <SecondsHand transform={`rotate(${seconds * 6},0,0)`}>
          <line x1='0' y1='30' x2='0' y2='-100' />
        </SecondsHand>
        <circle fill='red' r='3' />
      </g>
    </Svg>
  );
};

const Svg = styled('svg')`
  max-width: 150px;
  display: block;
  /* margin: 0 auto; */
`;

const Frame = styled('circle')`
  stroke: black;
  fill: transparent;
  stroke-width: 5;
`;

const SecondsHand = styled('g')`
  stroke: red;
`;
const MinutesHand = styled('g')`
  stroke: black;
`;
const HoursHand = styled('g')`
  stroke: black;
`;

const minuteMarkers = Array.from(new Array(60), (x, i) => i);
const fiveMinuteMarkers = minuteMarkers.filter((m) => m % 5 === 0);

const markerIndexToRadians = (markerIndex: number) => (Math.PI * markerIndex) / 30;

const drawMinuteMarker = (markerIndex: number) => {
  return (
    <g style={{ stroke: 'black' }}>
      <line
        x1={94 * Math.cos(markerIndexToRadians(markerIndex))}
        y1={94 * Math.sin(markerIndexToRadians(markerIndex))}
        x2={100 * Math.cos(markerIndexToRadians(markerIndex))}
        y2={100 * Math.sin(markerIndexToRadians(markerIndex))}
      />
    </g>
  );
};

const draw5MinMarker = (markerIndex: number) => {
  return (
    <g style={{ stroke: 'black' }}>
      <line
        strokeWidth='2'
        x1={90 * Math.cos(markerIndexToRadians(markerIndex))}
        y1={90 * Math.sin(markerIndexToRadians(markerIndex))}
        x2={100 * Math.cos(markerIndexToRadians(markerIndex))}
        y2={100 * Math.sin(markerIndexToRadians(markerIndex))}
      />
    </g>
  );
};

const hourLabels = ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '1', '2'];

const drawHourLabel = (label: string, hourLabelIndex: number) => {
  return (
    <text
      textAnchor='middle'
      alignmentBaseline='central'
      dominantBaseline='central'
      x={80 * Math.cos(markerIndexToRadians(hourLabelIndex * 5))}
      y={80 * Math.sin(markerIndexToRadians(hourLabelIndex * 5))}
    >
      {label}
    </text>
  );
};
