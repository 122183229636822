import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Iconify } from 'components/Iconify';
import { useEditTeacher } from 'hooks/useEditTeacher';
import { Controller } from 'react-hook-form';
import type { Teacher } from 'shared/types';
import { useGetStudentsQuery } from 'store/api/students';

interface AddStudentsProps {
  open: boolean;
  teacher: Teacher;
  onClose: () => void;
}

export const AddStudents = ({ open, teacher, onClose }: AddStudentsProps) => {
  const theme = useTheme();
  const { data: students, isLoading: studentsLoading } = useGetStudentsQuery();
  const { watch, control, handleSubmit, onSubmit, reset, errors, isLoading, errorMessage, isDirty } = useEditTeacher(
    teacher,
    onClose,
  );

  const studentsList = students?.map(({ id, firstName, lastName }) => ({
    id,
    name: `${firstName} ${lastName}`,
  }));

  const getStudentName = (id: number) => {
    const student = studentsList?.find(({ id: studentId }) => studentId === id);
    return student?.name;
  };

  const getSelectedItemStyles = (id: number) => {
    const selectedStudents = watch('students');
    if (selectedStudents && selectedStudents.includes(id)) {
      return {
        fontWeight: theme.typography.fontWeightMedium,
      };
    }
  };

  const isChecked = (id: number) => {
    const selectedStudents = watch('students');
    return Boolean(selectedStudents && selectedStudents.includes(id));
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      disableRestoreFocus
      open={open}
      onClose={handleOnClose}
      aria-labelledby='modal-new-teacher'
      aria-describedby='modal-new-teacher-form'
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 5,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <IconButton onClick={handleOnClose} sx={{ position: 'absolute', top: 15, right: 15 }}>
              <Iconify icon='eva:close-fill' />
            </IconButton>

            <Typography variant='h4'>{`${teacher.firstName} ${teacher.lastName}'s students`}</Typography>

            <Controller
              render={({ field }) => (
                <FormControl variant='standard'>
                  <Select
                    {...field}
                    multiple
                    name='students'
                    label='students'
                    labelId='students'
                    error={!!errors.students}
                    renderValue={(selected) => {
                      const students = selected.map(getStudentName);

                      if (selected.length === 0) {
                        return <em>Select students</em>;
                      }
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {students.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      );
                    }}
                  >
                    <MenuItem disabled value=''>
                      <em>Select students</em>
                    </MenuItem>
                    {studentsList?.map(({ id, name }) => (
                      <MenuItem key={`${id}-${name}`} value={id} style={getSelectedItemStyles(id)}>
                        <Checkbox checked={isChecked(id)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name='students'
              control={control}
            />

            {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}

            <LoadingButton
              type='submit'
              variant='contained'
              color='inherit'
              size='large'
              loading={isLoading || studentsLoading}
              disabled={!isDirty}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </Card>
    </Modal>
  );
};
