import { SvgColor } from 'components/SvgColor';
import { appRoutes } from 'routes/appRoutes';

import { Role } from './types';

const icon = (name: string) => <SvgColor src={require(`../assets/svg/${name}.svg`)} sx={{ width: 1, height: 1 }} />;

export const adminNav = [
  {
    title: 'dashboard',
    path: appRoutes.admin.dashboard(),
    icon: icon('ic_lecture'),
  },
  {
    title: 'users',
    path: appRoutes.admin.users(),
    icon: icon('ic_users'),
  },
  {
    title: 'teachers',
    path: appRoutes.admin.teachers(),
    icon: icon('ic_teacher'),
  },
  {
    title: 'students',
    path: appRoutes.admin.students(),
    icon: icon('ic_students'),
  },
];

export const adminMenuOptions = [
  {
    title: 'Home',
    path: appRoutes.root(),
    icon: 'eva:home-fill',
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: 'eva:person-fill',
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: 'eva:settings-2-fill',
  },
];

export const teacherNav = [
  {
    title: 'dashboard',
    path: appRoutes.teacher.dashboard(),
    icon: icon('ic_analytics'),
  },
  {
    title: 'students',
    path: appRoutes.teacher.students(),
    icon: icon('ic_user'),
  },
  {
    title: 'homeworks',
    path: appRoutes.teacher.homeworks(),
    icon: icon('ic_notes'),
  },
];

export const teacherMenuOptions = [
  {
    title: 'Home',
    path: '/',
    icon: 'eva:home-fill',
  },
  {
    title: 'Profile',
    path: '/teacher/profile',
    icon: 'eva:person-fill',
  },
  {
    title: 'Settings',
    path: '/teacher/settings',
    icon: 'eva:settings-2-fill',
  },
];

export const studentHomeMenuOptions = [
  {
    title: 'Dashboard',
    path: '/student/dashboard',
    icon: 'eva:home-fill',
  },
];

export const teacherHomeMenuOptions = [
  {
    title: 'Dashboard',
    path: '/teacher/dashboard',
    icon: 'eva:home-fill',
  },
];

export const adminHomeMenuOptions = [
  {
    title: 'Dashboard',
    path: '/admin/dashboard',
    icon: 'eva:home-fill',
  },
];

export const navConfig = {
  [Role.Admin]: {
    homeAvatarNav: adminHomeMenuOptions,
    nav: adminNav,
    menu: adminMenuOptions,
    redirectPath: appRoutes.admin.dashboard(),
  },
  [Role.Teacher]: {
    homeAvatarNav: teacherHomeMenuOptions,
    nav: teacherNav,
    menu: teacherMenuOptions,
    redirectPath: appRoutes.teacher.dashboard(),
  },
  [Role.Student]: {
    homeAvatarNav: studentHomeMenuOptions,
    nav: [],
    menu: [],
    redirectPath: appRoutes.student.dashboard(),
  },
};
