import { Container, Stack, Typography } from '@mui/material';
import { GeneralTable } from 'modules/GeneralTable';
import { studentsTableHead } from 'shared/tableConfig';
import type { Student } from 'shared/types';
import { useGetStudentsQuery } from 'store/api/students';

import { NewStudent } from './components/NewStudent';
import { StudentTableRow } from './components/StudentTableRow';

export const Students = () => {
  const { data } = useGetStudentsQuery();

  return (
    <Container>
      <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
        <Typography variant='h4'>Students</Typography>
        <NewStudent />
      </Stack>
      <GeneralTable<Student>
        data={data || []}
        tableHead={studentsTableHead}
        selectedProp={(data) => data.email}
        filterProp={(data) => data.lastName}
        keyProp={(data) => `${data.id}`}
        Row={StudentTableRow}
      />
    </Container>
  );
};
