import { yupResolver } from '@hookform/resolvers/yup';
import type { MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { Nullable } from 'shared/types';
import { getCreateTeacherBody } from 'shared/utils';
import { createTeacherValidationSchema } from 'shared/validationSchemas';
import { useCreateTeacherMutation } from 'store/api/teachers';
import type { CreateTeacherBody } from 'store/types';
import { getErrorMessage } from 'utils/getErrorMessage';

const defaultValues: CreateTeacherBody = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  students: [],
};

export const useCreateTeacher = (cb: () => void) => {
  const firstNameInput = useRef<Nullable<HTMLInputElement>>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createTeacher, { isLoading }] = useCreateTeacherMutation();
  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm<CreateTeacherBody>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(createTeacherValidationSchema),
  });

  const { ref, ...restInputRegister } = register('firstName');

  const firstNameRefCallback = (element: Nullable<HTMLInputElement>) => {
    ref(element);
    firstNameInput.current = element;
  };

  const onShowPassword: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const onSubmit: SubmitHandler<CreateTeacherBody> = async (data, event) => {
    const body = getCreateTeacherBody(data);
    try {
      await createTeacher(body).unwrap();
      cb();
    } catch (err) {
      const message = getErrorMessage(err);
      if (message) {
        setErrorMessage(message);
      }
    }
    event?.preventDefault();
    reset();
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  return {
    showPassword,
    errorMessage,
    register,
    handleSubmit,
    reset,
    errors,
    getValues,
    firstNameRefCallback,
    onShowPassword,
    restInputRegister,
    onSubmit,
    isLoading,
    control,
    watch,
  };
};
