import { AppBar, Box, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { Iconify } from 'components/Iconify';
import { UserMenuDropdown } from 'components/UserMenuDropdown';
import { useResponsive } from 'hooks/useResponsive';
import { HEADER, NAV } from 'shared/constants';
import type { Nullable, User } from 'shared/types';
import { bgBlur } from 'theme/css';

interface DashboardHeaderProps {
  className?: string;
  whoami?: Nullable<User>;
  onOpenNav?: () => void;
}

export const DashboardHeader = ({ onOpenNav }: DashboardHeaderProps) => {
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <Iconify icon='eva:menu-2-fill' />
        </IconButton>
      )}

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction='row' alignItems='center' spacing={1}>
        <UserMenuDropdown />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH + 1}px)`,
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};
