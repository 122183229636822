import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error as any).message === 'string'
  );
}

export const getErrorMessage = (error: unknown) => {
  if (isFetchBaseQueryError(error)) {
    if ('error' in error && typeof error.error === 'string') {
      return error.error;
    }
    if ('data' in error && isErrorWithMessage(error.data)) {
      return error.data.message;
    }
  } else if (isErrorWithMessage(error)) {
    return error.message;
  }
};
