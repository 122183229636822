import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/system';
import { forwardRef } from 'react';

export const SvgColor = forwardRef(({ src, sx }: { src: string; sx?: SxProps<Theme> }, ref) => (
  <Box
    component='span'
    className='svg-color'
    ref={ref}
    sx={{
      width: 24,
      height: 24,
      display: 'inline-block',
      bgcolor: 'currentColor',
      WebkitMask: `url(${src}) no-repeat center / contain`,
      mask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
  />
));

SvgColor.displayName = 'SvgColor';
