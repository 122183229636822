import { Box, Drawer, Link, Stack } from '@mui/material';
import logo from 'assets/images/logo.png';
import { NavItem } from 'components/NavItem';
import Scrollbar from 'components/Scrollbar';
import { useResponsive } from 'hooks/useResponsive';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NAV } from 'shared/constants';
import { navConfig } from 'shared/navConfig';
import type { Nullable, User } from 'shared/types';

import { Account } from '../Account';

interface NavProps {
  openNav: boolean;
  whoami?: Nullable<User>;
  pathname?: string;
  onCloseNav: () => void;
  onChangePath?: (path: string) => void;
  renderSideMenu?: ReactNode;
}

export const Nav = ({ whoami, openNav, onCloseNav }: NavProps) => {
  const { pathname } = useLocation();
  const upLg = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderMenu = (
    <Stack component='nav' spacing={0.5} sx={{ px: 2 }}>
      {whoami &&
        navConfig[whoami?.role].nav.map((item) => (
          <NavItem key={item.title} item={item} active={pathname === item.path} />
        ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          marginTop: '24px',
          marginLeft: '32px',
          width: 'fit-content',
        }}
      >
        <Link href='/'>
          <img src={logo} width={40} height={40} alt='logo' />
        </Link>
      </Box>
      <Account whoami={whoami} />
      {renderMenu}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};
