import { yupResolver } from '@hookform/resolvers/yup';
import type { MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { Nullable } from 'shared/types';
import { createStudentValidationSchema } from 'shared/validationSchemas';
import { useCreateStudentMutation } from 'store/api/students';
import type { CreateStudentBody } from 'store/types';
import { getErrorMessage } from 'utils/getErrorMessage';

const defaultValues: CreateStudentBody = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  teachers: [],
  balance: 0,
};

export const getCreateStudentBody = (data: CreateStudentBody) => {
  const { email, password } = data;

  const body = {
    email,
    password,
    ...(data.balance && { balance: data.balance }),
    ...(data.firstName && { firstName: data.firstName }),
    ...(data.lastName && { lastName: data.lastName }),
    ...(data.teachers && data.teachers.length > 0 && { teachers: data.teachers }),
  };

  return body;
};

export const useCreateStudent = (cb: () => void) => {
  const firstNameInput = useRef<Nullable<HTMLInputElement>>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createStudent, { isLoading }] = useCreateStudentMutation();
  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm<CreateStudentBody>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(createStudentValidationSchema),
  });

  const { ref, ...restInputRegister } = register('firstName');

  const firstNameRefCallback = (element: Nullable<HTMLInputElement>) => {
    ref(element);
    firstNameInput.current = element;
  };

  const onShowPassword: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const onSubmit: SubmitHandler<CreateStudentBody> = async (data, event) => {
    const body = getCreateStudentBody(data);
    try {
      await createStudent(body).unwrap();
      cb();
    } catch (err) {
      const message = getErrorMessage(err);
      if (message) {
        setErrorMessage(message);
      }
    }
    event?.preventDefault();
    reset();
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  return {
    showPassword,
    errorMessage,
    register,
    handleSubmit,
    reset,
    errors,
    getValues,
    firstNameRefCallback,
    onShowPassword,
    restInputRegister,
    onSubmit,
    isLoading,
    control,
    watch,
  };
};
