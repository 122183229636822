import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes/appRoutes';
import { useLogoutMutation } from 'store/api/auth';
import { baseApi } from 'store/api/base';
import { resetAuth } from 'store/slices/auth';

import { useAppDispatch } from './useAppDispatch';

export const useLogout = () => {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLogout = async () => {
    await logout().unwrap();
    dispatch(resetAuth());
    dispatch(baseApi.util.resetApiState());
    navigate(appRoutes.root());
  };

  return { onLogout };
};
