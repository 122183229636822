import { Loader } from 'components/Loader';
import { useAppSelector } from 'hooks/useAppSelector';
import { Navigate, Outlet } from 'react-router-dom';
import { useWhoamiQuery } from 'store/api/auth';
import { selectIsAuthed } from 'store/slices/auth';

import { appRoutes } from './appRoutes';

export const PublicRoute = () => {
  const isAuthed = useAppSelector(selectIsAuthed);
  const { data, isLoading } = useWhoamiQuery();

  if (isLoading) return <Loader />;

  if (isAuthed && data?.role) {
    const navigateTo = appRoutes[data?.role].dashboard();
    return <Navigate to={navigateTo} replace />;
  }

  return <Outlet />;
};
