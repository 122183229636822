import { Box } from '@mui/material';
import { DashboardHeader } from 'components/DashboardHeader';
import { Nav } from 'components/Nav';
import { useResponsive } from 'hooks/useResponsive';
import { type ReactNode, useState } from 'react';
import { HEADER, NAV, SPACING } from 'shared/constants';
import { useWhoamiQuery } from 'store/api/auth';

export const DashboardLayout = ({ children }: { children: ReactNode }) => {
  const lgUp = useResponsive('up', 'lg');
  const { data } = useWhoamiQuery();
  const [openNav, setOpenNav] = useState(false);

  return (
    <div>
      <DashboardHeader whoami={data} onOpenNav={() => setOpenNav(true)} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav whoami={data} openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Box
          component='main'
          sx={{
            flexGrow: 1,
            minHeight: 1,
            display: 'flex',
            flexDirection: 'column',
            py: `${HEADER.H_MOBILE + SPACING}px`,
            ...(lgUp && {
              px: 2,
              py: `${HEADER.H_DESKTOP + SPACING}px`,
              width: `calc(100% - ${NAV.WIDTH}px)`,
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </div>
  );
};
