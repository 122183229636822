import { alpha, Box, Link, ListItemButton } from '@mui/material';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface NavItemProps {
  item: {
    title: string;
    path: string;
    icon: ReactNode;
  };
  active?: boolean;
}

export function NavItem({ item, active = false }: NavItemProps) {
  const navigate = useNavigate();

  return (
    <ListItemButton
      component={Link}
      onClick={() => navigate(item.path)}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component='span'>{item.title}</Box>
    </ListItemButton>
  );
}
