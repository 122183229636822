import { Container, Stack, Typography } from '@mui/material';
import { GeneralTable } from 'modules/GeneralTable';
import { usersTableHead } from 'shared/tableConfig';
import type { User } from 'shared/types';
import { useGetUsersQuery } from 'store/api/users';

import { NewUser } from './components/NewUser';
import { UserTableRow } from './components/UserTableRow';

export const Users = () => {
  const { data } = useGetUsersQuery();

  return (
    <Container>
      <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
        <Typography variant='h4'>Users</Typography>
        <NewUser />
      </Stack>

      <GeneralTable<User>
        data={data || []}
        tableHead={usersTableHead}
        selectedProp={(data) => data.email}
        filterProp={(data) => data.firstName}
        keyProp={(data) => `${data.id}`}
        Row={UserTableRow}
      />
    </Container>
  );
};
