import type { Middleware } from '@reduxjs/toolkit';
import { isAction } from '@reduxjs/toolkit';
import { config } from 'shared/config';
import type { RootState } from 'store/types';

export const localStorageMiddleware: Middleware<{
  type: string;
  payload: unknown;
}> = (store) => (next) => (action) => {
  const result = next(action);
  const storeState: RootState = store.getState();

  if (isAction(action)) {
    if (action.type === 'auth/setAuth') {
      localStorage.setItem(config.AUTH_LOCAL_STORAGE_KEY, JSON.stringify(storeState.auth));
    }
    if (action.type === 'auth/resetAuth') {
      localStorage.removeItem(config.AUTH_LOCAL_STORAGE_KEY);
    }
  }

  return result;
};
