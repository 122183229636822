import { format, parseISO } from 'date-fns';
import { NOT_APPLICABLE_TITLE } from 'shared/constants';
import type { Student } from 'shared/types';
import { type Nullable, Role, type Teacher } from 'shared/types';
import type {
  AreDirtyStudents,
  AreDirtyTeachers,
  CreateTeacherBody,
  UpdateStudentBody,
  UpdateTeacherBody,
} from 'store/types';

export const formatDate = (iso: Nullable<string>) => (iso ? format(parseISO(iso), 'MM/dd/yy H:mm') : 'n/a');

export const notApplicable = (string: Nullable<string>) => string ?? NOT_APPLICABLE_TITLE;

export const compareTwoArrays = (arr1?: number[], arr2?: number[]) => {
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item) => arr2.includes(item));
};

export const getCreateTeacherBody = (data: CreateTeacherBody) => {
  const { email, password } = data;

  const body = {
    email,
    password,
    ...(data.firstName && { firstName: data.firstName }),
    ...(data.lastName && { lastName: data.lastName }),
    ...(data.students && data.students.length > 0 && { students: data.students }),
  };

  return body;
};

export const getEditTecherValues = (data: Teacher) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  students: data.students.map(({ id }) => id),
});

export const getEditStudentValues = (data: Student) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  balance: data.balance,
  teachers: data.teachers.map(({ id }) => id),
});

export const getUpdateTeacherBody = (data: UpdateTeacherBody, areDirty: AreDirtyTeachers) => {
  const body = {
    ...(areDirty['firstName'] && data.firstName && { firstName: data.firstName }),
    ...(areDirty['lastName'] && data.lastName && { lastName: data.lastName }),
    ...(areDirty['students'] && data.students && data.students.length > 0 && { students: data.students }),
    ...(data.students && data.students.length === 0 && { students: [] }),
  };

  return body;
};

export const getUpdateStudentBody = (data: UpdateStudentBody, areDirty: AreDirtyStudents) => {
  const body = {
    ...(areDirty['firstName'] && data.firstName && { firstName: data.firstName }),
    ...(areDirty['lastName'] && data.lastName && { lastName: data.lastName }),
    ...(areDirty['teachers'] && data.teachers && data.teachers.length > 0 && { teachers: data.teachers }),
    ...(data.teachers && data.teachers.length === 0 && { teachers: [] }),
    ...(areDirty['balance'] && data.balance && { balance: data.balance }),
  };

  return body;
};

export const getDeleteUrlByRole = (role: string, id: number, uuid: string) => {
  const map: { [key: string]: string } = {
    [Role.Admin]: `/users/${id}`,
    [Role.Teacher]: `/teachers/${uuid}/force`,
    [Role.Student]: `/students/${uuid}`,
  };

  return map[role];
};

export const getRestoreUrlByRole = (role: string, id: number, uuid: string) => {
  const map: { [key: string]: string } = {
    [Role.Admin]: `/users/${id}`,
    [Role.Teacher]: `/teachers/${uuid}/restore`,
    [Role.Student]: `/students/${uuid}`,
  };

  return map[role];
};
