import { Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { useGeneralTable } from 'hooks/useGeneralTable';
import type { ChangeEvent, ComponentType } from 'react';
import type { Item } from 'shared/types';
import type { MapProperty } from 'shared/types';

import { GeneralTableEmptyRows } from './components/GeneralTableEmptyRows';
import { GeneralTableHead } from './components/GeneralTableHead';
import { GeneralTableNoData } from './components/GeneralTableNoData';
import { GeneralTableToolbar } from './components/GeneralTableToolbar';

export interface RowProps<T> {
  data: T;
  selected: boolean;
  handleClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export interface GeneralTableProps<T> {
  data: T[];
  tableHead: Item[];
  selectedProp: MapProperty<T>;
  filterProp: MapProperty<T>;
  keyProp: MapProperty<T>;
  Row: ComponentType<RowProps<T>>;
}

export function GeneralTable<T>({ data, tableHead, Row, filterProp, selectedProp, keyProp }: GeneralTableProps<T>) {
  const {
    emptyRowsCount,
    handleSort,
    handleSelectAllClick,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    dataFiltered,
    notFound,
    handleClick,
    selected,
    page,
    order,
    orderBy,
    filterName,
    rowsPerPage,
  } = useGeneralTable({ data, filterProp, selectedProp });

  return (
    <Card>
      <GeneralTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

      <Scrollbar>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Table sx={{ minWidth: 800 }}>
            <GeneralTableHead
              order={order}
              orderBy={orderBy}
              rowCount={data?.length || 0}
              numSelected={selected.length}
              onRequestSort={handleSort}
              onSelectAllClick={handleSelectAllClick}
              headLabel={tableHead}
            />
            <TableBody>
              {dataFiltered.map((data) => (
                <Row
                  key={keyProp(data)}
                  data={data}
                  selected={selected.indexOf(selectedProp(data)) !== -1}
                  handleClick={(event) => handleClick(event, selectedProp(data))}
                />
              ))}

              <GeneralTableEmptyRows height={77} emptyRows={emptyRowsCount} />

              {notFound && <GeneralTableNoData query={filterName} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        page={page}
        component='div'
        count={data?.length || 0}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
