import { Avatar, Box, Checkbox, IconButton, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { Iconify } from 'components/Iconify';
import { Loader } from 'components/Loader';
import type { RowProps } from 'modules/GeneralTable';
import { useState } from 'react';
import type { Teacher } from 'shared/types';
import { useDeleteTeacherMutation } from 'store/api/teachers';

import { AddStudents } from '../AddStudents';
import { ExpandableStudentsRow } from '../ExpandableStudentsRow';

export const TeacherTableRow = ({ data, selected, handleClick }: RowProps<Teacher>) => {
  const [openExpand, setOpenExpand] = useState(false);
  const [openAddStudents, setOpenAddStudents] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteTeacher, { isLoading }] = useDeleteTeacherMutation();

  const { id, firstName, lastName, email, updatedAt } = data;
  const name = `${firstName} ${lastName}`;

  const onDeleteTeacher = () => {
    setOpenConfirmation(true);
    setOpenExpand(false);
  };

  const onConfirm = () => {
    deleteTeacher(id);

    setOpenConfirmation(false);
  };

  const onCancel = () => {
    setOpenConfirmation(false);
  };

  const onCloseAddStudents = () => {
    setOpenAddStudents(false);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role='checkbox' selected={selected || openExpand}>
        <TableCell padding='checkbox'>
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell component='th' scope='row' padding='checkbox'>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar alt={name} />
            <Typography variant='subtitle2' noWrap>
              <Link sx={{ color: 'inherit', textDecoration: 'none' }} href={`/admin/teachers/${data.id}`}>
                {name}
              </Link>
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{email}</TableCell>

        <TableCell>{updatedAt}</TableCell>

        <TableCell align='center'>
          {data.students.length === 0 ? (
            <IconButton onClick={() => setOpenAddStudents(true)}>
              <Iconify icon='tdesign:user-add' />
            </IconButton>
          ) : (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpenExpand(!openExpand)}
              disabled={data.students.length === 0}
            >
              {openExpand ? <Iconify icon='mdi:keyboard-arrow-up' /> : <Iconify icon='mdi:keyboard-arrow-down' />}
            </IconButton>
          )}
        </TableCell>

        <TableCell align='right'>
          {isLoading ? (
            <Box sx={{ width: 36, height: 36 }}>
              <Loader size={20} />
            </Box>
          ) : (
            <IconButton onClick={onDeleteTeacher}>
              <Iconify icon='eva:trash-2-outline' />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <ExpandableStudentsRow
        openAddStudents={() => setOpenAddStudents(true)}
        open={data.students.length === 0 ? false : openExpand}
        students={data.students}
      />
      <AddStudents teacher={data} open={openAddStudents} onClose={onCloseAddStudents} />
      <ConfirmationDialog
        title='Delete Teacher'
        content={`Are you sure you want to delete ${name}?`}
        open={openConfirmation}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};
