import { Avatar, Box, Checkbox, IconButton, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { Iconify } from 'components/Iconify';
import { Loader } from 'components/Loader';
import type { RowProps } from 'modules/GeneralTable';
import { useState } from 'react';
import type { Student } from 'shared/types';
import { useDeleteStudentMutation } from 'store/api/students';

export const StudentTableRow = ({ data, selected, handleClick }: RowProps<Student>) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteStudent, { isLoading }] = useDeleteStudentMutation();

  const { id, firstName, lastName, email, balance } = data;

  const name = `${firstName} ${lastName}`;

  const onDeleteStudent = () => {
    setOpenConfirmation(true);
  };

  const onConfirm = () => {
    deleteStudent(id);
    setOpenConfirmation(false);
  };

  const onCancel = () => {
    setOpenConfirmation(false);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role='checkbox' selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>

        <TableCell component='th' scope='row' padding='none'>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar alt={name} />
            <Typography variant='subtitle2' noWrap>
              <Link sx={{ color: 'inherit', textDecoration: 'none' }} href={`/admin/students/${data.id}`}>
                {name}
              </Link>
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{email}</TableCell>

        <TableCell>
          <Stack
            direction='row'
            spacing={1}
            flexWrap='wrap'
            sx={{
              '&>:not(style)~:not(style)': {
                ml: 0,
              },
            }}
          >
            {data.teachers.map(({ id, firstName, lastName }, index) => (
              <div key={id}>
                <Box component='span'>{`${firstName} ${lastName}`}</Box>
                {data.teachers.length - 1 !== index && (
                  <Box component='span' sx={{ px: '5px' }}>
                    {'|'}
                  </Box>
                )}
              </div>
            ))}
          </Stack>
        </TableCell>

        <TableCell align='center'>{balance}</TableCell>

        <TableCell align='right'>
          {isLoading ? (
            <Box sx={{ width: 36, height: 36 }}>
              <Loader size={20} />
            </Box>
          ) : (
            <IconButton onClick={onDeleteStudent}>
              <Iconify icon='eva:trash-2-outline' />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        title='Delete Student'
        content={`Are you sure you want to delete ${name}?`}
        open={openConfirmation}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};
