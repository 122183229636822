import type { Theme } from '@emotion/react';
import type { IconifyIcon } from '@iconify/react';
import { Icon } from '@iconify/react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

export const Iconify = forwardRef(
  (
    {
      icon,
      width = 20,
      sx,
      ...other
    }: {
      icon: string | IconifyIcon;
      width?: number;
      sx?: SxProps<Theme>;
    },
    ref,
  ) => (
    <Box
      ref={ref}
      component={Icon}
      className='component-iconify'
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  ),
);

Iconify.displayName = 'Iconify';
