import type { BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { jwtDecode } from 'jwt-decode';
import { config } from 'shared/config';
import { ENDPOINTS } from 'shared/endpoints';
import { resetAuth, setAuth } from 'store/slices/auth';
import type { RootState } from 'store/types';
import type { AuthRoot } from 'store/types';
import { isTokenExpiringSoon } from 'utils/isTokenExpiringSoon';

const refreshToken = async (api: BaseQueryApi, extraOptions: object) => {
  const refreshResult = await baseQuery({ url: ENDPOINTS.REFRESH, method: 'POST' }, api, extraOptions);
  if (refreshResult.data) {
    api.dispatch(setAuth(refreshResult.data as AuthRoot));
  } else {
    await baseQuery({ url: ENDPOINTS.LOGOUT, method: 'POST' }, api, extraOptions);
    api.dispatch(resetAuth());
    return false;
  }
  return true;
};

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();

  const state = api.getState() as RootState;
  const { accessToken } = state.auth;
  const expiresAt = accessToken ? jwtDecode(accessToken).exp : '';

  if (accessToken && expiresAt && isTokenExpiringSoon(expiresAt)) {
    const refreshed = await refreshToken(api, extraOptions);
    if (!refreshed) {
      return { error: { status: 401, data: 'Session expired' } };
    }
  }

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshed = await refreshToken(api, extraOptions);

        if (refreshed) {
          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Users', 'Teachers', 'Students'],
  endpoints: () => ({}),
});
