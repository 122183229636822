import { Box, CircularProgress, Typography } from '@mui/material';

type LoaderProps = {
  label?: string;
  size?: number;
};

export const Loader = ({ label, size = 60 }: LoaderProps) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
    }}
  >
    <CircularProgress size={size} thickness={2} />
    {label && (
      <Typography variant='subtitle2' color='text.secondary'>
        {label}
      </Typography>
    )}
  </Box>
);
