import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { PropsWithChildren } from 'react';
import { forwardRef, memo } from 'react';

import { StyledRootScrollbar, StyledScrollbar } from './styled';

const Scrollbar = forwardRef(
  (
    {
      children,
      sx,
      ...rest
    }: PropsWithChildren<{
      sx?: SxProps<Theme>;
    }>,
    ref,
  ) => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    if (mobile) {
      return (
        <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...rest}>
          {children}
        </Box>
      );
    }

    return (
      <StyledRootScrollbar>
        <StyledScrollbar
          sx={sx}
          scrollableNodeProps={{
            ref,
          }}
          clickOnTrack={false}
          {...rest}
        >
          {children}
        </StyledScrollbar>
      </StyledRootScrollbar>
    );
  },
);

Scrollbar.displayName = 'Scrollbar';

export default memo(Scrollbar);
