import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { baseApi } from './api/base';
import { localStorageMiddleware } from './middlewares/localStorageMiddleware';
import { preloadState } from './preloadState';
import authReducer from './slices/auth';

const rootReducer = combineReducers({
  auth: authReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware, baseApi.middleware),
  preloadedState: preloadState(),
});
