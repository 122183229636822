import type { GeneralTableProps } from 'modules/GeneralTable';
import { applyFilter, emptyRows, getComparator } from 'modules/GeneralTable/utils';
import { useState } from 'react';
import type { Order } from 'shared/types';

type UseGeneralTableProps<T> = Omit<GeneralTableProps<T>, 'tableHead' | 'keyProp' | 'keyProp' | 'Row'>;

export function useGeneralTable<T>({ data, filterProp, selectedProp }: UseGeneralTableProps<T>) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selected, setSelected] = useState<string[]>([]);
  const [filterName, setFilterName] = useState('');

  const emptyRowsCount = emptyRows(page, rowsPerPage, data.length);

  const handleSort = (event: React.MouseEvent<unknown, MouseEvent>, id: string) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data?.map(selectedProp) || [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const dataFiltered = applyFilter<T>({
    data,
    comparator: getComparator<T>(order, orderBy as keyof T),
    filterName,
    filterProp,
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const notFound = !dataFiltered.length && !!filterName;

  return {
    emptyRowsCount,
    handleSort,
    handleSelectAllClick,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    dataFiltered,
    notFound,
    handleClick,
    selected,
    page,
    order,
    orderBy,
    filterName,
    rowsPerPage,
  };
}
