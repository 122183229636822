import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/router';
import { ThemeProvider } from 'theme';

export const App = () => {
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
