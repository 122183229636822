import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Iconify } from 'components/Iconify';
import type { StudentsInTeacher } from 'shared/types';

interface ExpandableStudentsRowProps {
  open: boolean;
  students: StudentsInTeacher;
  openAddStudents: () => void;
}

export const ExpandableStudentsRow = ({ open, students, openAddStudents }: ExpandableStudentsRowProps) => {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Stack direction='row' gap={1} alignItems='center' sx={{ mb: '8px' }}>
              <Typography variant='h6' component='div'>
                Students
              </Typography>
              <IconButton aria-label='add' onClick={openAddStudents}>
                <Iconify icon='tabler:user-edit' />
              </IconButton>
            </Stack>
            <Table size='small' aria-label='purchases'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell align='center'>Balance ($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell component='th' scope='row'>
                      {`${student.firstName} ${student.lastName}`}
                    </TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.updatedAt}</TableCell>
                    <TableCell align='center'>{student.balance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
